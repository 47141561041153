import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["classNames", "emptyComponent", "headerComponent", "itemComponent", "layout", "items", "status", "translations", "sendEvent"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { cx } from "../lib/index.js";
import { createDefaultEmptyComponent, createDefaultHeaderComponent, createDefaultItemComponent, createListComponent } from "./recommend-shared/index.js";
export function createFrequentlyBoughtTogetherComponent(_ref) {
  var createElement = _ref.createElement,
    Fragment = _ref.Fragment;
  return function FrequentlyBoughtTogether(userProps) {
    var _userProps$classNames = userProps.classNames,
      classNames = _userProps$classNames === void 0 ? {} : _userProps$classNames,
      _userProps$emptyCompo = userProps.emptyComponent,
      EmptyComponent = _userProps$emptyCompo === void 0 ? createDefaultEmptyComponent({
        createElement: createElement,
        Fragment: Fragment
      }) : _userProps$emptyCompo,
      _userProps$headerComp = userProps.headerComponent,
      HeaderComponent = _userProps$headerComp === void 0 ? createDefaultHeaderComponent({
        createElement: createElement,
        Fragment: Fragment
      }) : _userProps$headerComp,
      _userProps$itemCompon = userProps.itemComponent,
      ItemComponent = _userProps$itemCompon === void 0 ? createDefaultItemComponent({
        createElement: createElement,
        Fragment: Fragment
      }) : _userProps$itemCompon,
      _userProps$layout = userProps.layout,
      Layout = _userProps$layout === void 0 ? createListComponent({
        createElement: createElement,
        Fragment: Fragment
      }) : _userProps$layout,
      items = userProps.items,
      status = userProps.status,
      userTranslations = userProps.translations,
      sendEvent = userProps.sendEvent,
      props = _objectWithoutProperties(userProps, _excluded);
    var translations = _objectSpread({
      title: 'Frequently bought together',
      sliderLabel: 'Frequently bought together products'
    }, userTranslations);
    var cssClasses = {
      root: cx('ais-FrequentlyBoughtTogether', classNames.root),
      emptyRoot: cx('ais-FrequentlyBoughtTogether', classNames.root, 'ais-FrequentlyBoughtTogether--empty', classNames.emptyRoot, props.className),
      title: cx('ais-FrequentlyBoughtTogether-title', classNames.title),
      container: cx('ais-FrequentlyBoughtTogether-container', classNames.container),
      list: cx('ais-FrequentlyBoughtTogether-list', classNames.list),
      item: cx('ais-FrequentlyBoughtTogether-item', classNames.item)
    };
    if (items.length === 0 && status === 'idle') {
      return createElement("section", _extends({}, props, {
        className: cssClasses.emptyRoot
      }), createElement(EmptyComponent, null));
    }
    return createElement("section", _extends({}, props, {
      className: cssClasses.root
    }), createElement(HeaderComponent, {
      classNames: cssClasses,
      items: items,
      translations: translations
    }), createElement(Layout, {
      classNames: cssClasses,
      itemComponent: ItemComponent,
      items: items,
      sendEvent: sendEvent
    }));
  };
}